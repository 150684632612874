
span.copy-element:hover::after {
    position: absolute;
    width: 100px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 120%;
    background-color: lightgray;
    color: antiquewhite;
    content: "click to copy";
    border: solid 1px antiquewhite;
    text-align: center;
    border-radius: 4px;
}

span.copy-clicked {
    background-color: none;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid transparent;
}

span.copy-clicked::after {
    position: absolute;
    width: 100px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 120%;
    background-color: lightgray;
    color: antiquewhite;
    content: "copied";
    border: solid 1px antiquewhite;
    text-align: center;
    border-radius: 4px;
}

span.copy-element:hover {
    border: 1px solid black;
    border-radius: 2px;
}

span.copy-element {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    transition: border 0.6s ease;
    border: 1px solid transparent;
}